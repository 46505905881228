<template>
  <div class="help">
    <ul class="help__list">
      <li
        v-for="link in links"
        :key="link.name"
        class="help__item"
      >
        <x-link
          primary
          :href="link.url"
        >
          {{ link.name }}
        </x-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { HELP_DOCS } from '@/constants/links'

export default {
  data () {
    return {
      links: [
        {
          name: 'Управление Ценами и Остатками',
          url: 'https://brassy-countess-52e.notion.site/52c75e4a892c4f24b0a942560d1f2b5a'
        },
        {
          name: 'Продвижение',
          url: 'https://brassy-countess-52e.notion.site/AliExpress-162ef036a15346c58ff1a1a9fc189959'
        },
        {
          name: 'Товары',
          url: 'https://brassy-countess-52e.notion.site/150c4eaf2e824d8c84f87daad200612c'
        },
        {
          name: 'Аналитика',
          url: 'https://brassy-countess-52e.notion.site/9c33d0a09ced430488c9114f0b5b9160'
        },
        /*        {
          name: 'Фулфилмент XWAY',
          url: 'https://brassy-countess-52e.notion.site/XWAY-08801d8e7824485d98cd5a9c27a13522'
        }, */
        {
          name: 'Внутренняя аналитика',
          url: 'https://brassy-countess-52e.notion.site/43fd05d018a04d08823617be716698d8'
        },
        {
          name: 'Перенос карточек MP2MP',
          url: 'https://brassy-countess-52e.notion.site/e493d64bf69948169e90f9acf9e57301'
        },
        {
          name: HELP_DOCS.SELF_REDEMPTION.name,
          url: HELP_DOCS.SELF_REDEMPTION.link
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .help
    &__list
      list-style none

    &__item
      margin-bottom 12px
</style>
